var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "n-page",
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 8 } },
            [
              _c("club-subscription-overview", { attrs: { cards: _vm.cards } }),
              _c("div", { staticClass: "add-new-card gx-text-right" }),
              _c(
                "a-card",
                { staticClass: "gx-card-full gx-card-header-full" },
                [
                  _c(
                    "div",
                    { attrs: { slot: "title" }, slot: "title" },
                    [
                      _c(
                        "a-row",
                        { attrs: { type: "flex" } },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 12, sm: 14, md: 12, lg: 14 } },
                            [
                              _vm._v(
                                "\n              Active Cards\n            "
                              )
                            ]
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 12, sm: 10, md: 12, lg: 10 } },
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: {
                                    margin: "0",
                                    float: "right",
                                    height: "33px"
                                  },
                                  attrs: {
                                    block: "",
                                    type: "primary",
                                    size: "small"
                                  },
                                  on: { click: _vm.openNewCardModal }
                                },
                                [
                                  _vm._v(
                                    "\n                Add new Card\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.cards.length > 0
                    ? _c(
                        "div",
                        { staticClass: "credit-cards" },
                        _vm._l(_vm.cards, function(card, index) {
                          return _c("stripe-card-preview", {
                            key: index,
                            attrs: { card: card },
                            on: {
                              remove: _vm.removeCard,
                              setDefault: _vm.setDefault
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.cardsLoading ? _c("n-section-loading") : _vm._e(),
                  !_vm.cardsLoading && _vm.cards.length === 0
                    ? _c(
                        "div",
                        [
                          _c("n-section-empty", {
                            attrs: {
                              warning:
                                "You have no active cards, please add your first card"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 16 } },
            [
              _c(
                "a-card",
                { staticClass: "gx-card-table-full" },
                [
                  _c("stripe-billing-history-table", {
                    attrs: { billing: _vm.billing, loading: _vm.billingLoading }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("stripe-new-card-modal", {
        attrs: { visible: _vm.newCardModalVisible },
        on: { token: _vm.saveCard, close: _vm.closeNewCardModalVisible }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }